import { ScrollArea, ScrollBar } from '@/Components/ScrollArea'
import { cn } from '@/Lib'
import { Link } from '@inertiajs/react'
import type { ComponentPropsWithoutRef, ContextType } from 'react'
import { createContext, useContext, useState } from 'react'

function Table({
  className,
  children,
  ...props
}: ComponentPropsWithoutRef<'table'>) {
  return (
    <ScrollArea>
      <ScrollBar orientation={'horizontal'} />
      <table className={cn('w-full', className)} {...props}>
        {children}
      </table>
    </ScrollArea>
  )
}

function TableHead({ className, ...props }: ComponentPropsWithoutRef<'thead'>) {
  return <thead className={cn('w-full', className)} {...props} />
}

function TableBody(props: ComponentPropsWithoutRef<'tbody'>) {
  return <tbody {...props} />
}

const TableRowContext = createContext<{
  href?: string
  target?: string
  title?: string
}>({
  href: undefined,
  target: undefined,
  title: undefined
})

function TableRow({
  href,
  target,
  title,
  className,
  children,
  ...props
}: {
  href?: string
  target?: string
  title?: string
} & ComponentPropsWithoutRef<'tr'>) {
  return (
    <TableRowContext.Provider
      value={{ href, target, title } as ContextType<typeof TableRowContext>}
    >
      <tr
        {...props}
        className={cn(
          href &&
            'has-[[data-row-link][data-focus]]:outline-primary hover:bg-nav has-[[data-row-link][data-focus]]:bg-nav has-[[data-row-link][data-focus]]:outline has-[[data-row-link][data-focus]]:outline-2 has-[[data-row-link][data-focus]]:-outline-offset-2',
          className
        )}
      >
        {children}
      </tr>
    </TableRowContext.Provider>
  )
}

function TableHeader({ className, ...props }: ComponentPropsWithoutRef<'th'>) {
  return (
    <th
      {...props}
      className={cn('p-3 text-start align-top font-medium', className)}
    />
  )
}

function TableCell({
  className,
  children,
  ...props
}: ComponentPropsWithoutRef<'td'>) {
  const { href, target, title } = useContext(TableRowContext)
  const [cellRef, setCellRef] = useState<HTMLElement | null>(null)

  return (
    <td
      ref={href ? setCellRef : undefined}
      {...props}
      className={cn(
        'relative p-2 text-medium first:rounded-l-xl last:rounded-r-xl',
        className
      )}
    >
      {href && (
        <Link
          data-row-link
          href={href}
          target={target}
          aria-label={title}
          tabIndex={cellRef?.previousElementSibling === null ? 0 : -1}
          className='absolute inset-0 focus:outline-none'
        />
      )}
      {children}
    </td>
  )
}

export { Table, TableBody, TableCell, TableHead, TableHeader, TableRow }
