import { cn } from '@/Lib'
import type { HTMLAttributes } from 'react'
import { forwardRef } from 'react'

const Alert = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          'flex w-full gap-x-2 rounded-xl bg-slate-100 p-4',
          className
        )}
        {...props}
      />
    )
  }
)
Alert.displayName = 'Alert'

const AlertTitle = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => {
  return (
    <h3
      ref={ref}
      className={cn('font-medium text-medium text-strong', className)}
      {...props}
    />
  )
})
AlertTitle.displayName = 'AlertTitle'

const AlertBody = forwardRef<
  HTMLParagraphElement,
  HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => {
  return <p ref={ref} className={cn('text-medium', className)} {...props} />
})
AlertBody.displayName = 'AlertBody'

const AlertContent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn('flex w-full flex-col gap-y-2', className)}
      {...props}
    />
  )
)
AlertContent.displayName = 'AlertContent'

export { Alert, AlertBody, AlertContent, AlertTitle }
