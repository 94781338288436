import { cn } from '@/Lib'
import { FC, ReactNode, useMemo } from 'react'
import { Colours } from './Colours'

export type ChipProps = {
  children?: ReactNode
  colour?: Colours
  className?: string
  muted?: boolean
}

export const Chip: FC<ChipProps> = ({
  children,
  colour = Colours.slate,
  className,
  muted = false
}) => {
  const colourVariant = useMemo(() => {
    switch (colour) {
      case Colours.red:
        return muted ? 'text-red-950 bg-red-100' : 'text-red-950 bg-red-300'
      case Colours.sky:
        return muted ? 'text-sky-950 bg-sky-100' : 'text-sky-950 bg-sky-300'
      case Colours.green:
        return muted
          ? 'text-green-950 bg-green-100'
          : 'text-green-950 bg-green-300'
      case Colours.orange:
        return muted
          ? 'text-orange-950 bg-orange-100'
          : 'text-orange-950 bg-orange-300'
      case Colours.blue:
        return muted ? 'text-blue-950 bg-blue-100' : 'text-blue-950 bg-blue-300'
      case Colours.indigo:
        return muted
          ? 'text-indigo-950 bg-indigo-100'
          : 'text-indigo-950 bg-indigo-300'
      case Colours.purple:
        return muted
          ? 'text-purple-950 bg-purple-100'
          : 'text-purple-950 bg-purple-300'
      case Colours.yellow:
        return muted
          ? 'text-yellow-950 bg-yellow-100'
          : 'text-yellow-950 bg-yellow-300'
      case Colours.lime:
        return muted ? 'text-lime-950 bg-lime-100' : 'text-lime-950 bg-lime-300'
      case Colours.rose:
        return muted ? 'text-rose-950 bg-rose-100' : 'text-rose-950 bg-rose-300'
      case Colours.slate:
      default:
        return muted
          ? 'text-slate-950 bg-slate-100'
          : 'text-slate-950 bg-slate-300'
    }
  }, [colour, muted])

  return (
    <div
      className={cn(
        'flex max-w-max items-center justify-center rounded px-1.5 py-1 text-xs',
        colourVariant,
        className
      )}
    >
      {children}
    </div>
  )
}
