export enum Colours {
  red,
  sky,
  green,
  orange,
  blue,
  indigo,
  purple,
  yellow,
  lime,
  rose,
  slate
}
