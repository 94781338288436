import { cn } from '@/Lib'
import type { FC, ReactNode } from 'react'

type IconProps = {
  // TODO Refactor children?: Icons to enforce the use of codepoints
  children?: ReactNode
  className?: string
  large?: boolean
}

/**
 * The name of the material icon:
 * https://fonts.google.com/icons
 *
 * Find codepoints here
 * https://github.com/google/material-design-icons/blob/master/font/MaterialIcons-Regular.codepoints
 */
export enum Icons {
  arrow_right_alt = '\ue941'
}

export const Icon: FC<IconProps> = ({ className, children, large = false }) => {
  return (
    <span
      aria-hidden='true'
      className={cn(
        'inline-block select-none whitespace-nowrap fill-current text-center font-normal normal-case not-italic tracking-normal antialiased',
        large
          ? 'size-12 font-icon-large text-5xl leading-[48px]'
          : 'size-6 font-icon text-2xl leading-6',
        className
      )}
    >
      {children}
    </span>
  )
}
