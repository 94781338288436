import { cn } from '@/Lib'
import * as SliderPrimitive from '@radix-ui/react-slider'
import {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  useMemo
} from 'react'
import { Colours } from './Colours'

type SliderProps = {
  colour?: Colours
} & ComponentPropsWithoutRef<typeof SliderPrimitive.Root>

const Slider = forwardRef<ElementRef<typeof SliderPrimitive.Root>, SliderProps>(
  ({ colour, className, ...props }, forwardedRef) => {
    // We manually ensure that the range is a percentage so that we don't have visually broken thumbs
    const percentages = useMemo(() => {
      const value = props.value || props.defaultValue
      const x = typeof value == 'undefined' ? 0 : value[0]
      const max = props.max ?? 1
      const min = props.min ?? 0
      if (x < min) return { display: 0, value: 0 }
      if (x > max) return { display: 1, value: 1 }
      return { display: (x - min) / (max - min), value: x }
    }, [props.defaultValue, props.max, props.min, props.value])

    const colourVariants = useMemo(() => {
      switch (colour) {
        case Colours.green:
          return {
            bg: 'bg-green-400',
            thumb: 'bg-green-500 group-focus:bg-green-600'
          }
        case Colours.sky:
          return {
            bg: 'bg-sky-400',
            thumb: 'bg-sky-500 group-focus:bg-sky-600'
          }
        case Colours.orange:
          return {
            bg: 'bg-orange-400',
            thumb: 'bg-orange-500 group-focus:bg-orange-600'
          }
        default:
          return {
            bg: 'bg-slate-400',
            thumb: 'bg-slate-500 group-focus:bg-slate-600'
          }
      }
    }, [colour])

    return (
      <div className='rounded-xl bg-nav p-1'>
        <SliderPrimitive.Root
          className={cn(
            'relative flex h-10 w-full touch-none select-none items-center overflow-hidden rounded-lg',
            className
          )}
          {...props}
          ref={forwardedRef}
        >
          <span className='absolute right-1 text-sm font-medium tabular-nums'>
            {(percentages.value * 100).toFixed(0)}%
          </span>
          <SliderPrimitive.Track className='relative h-full w-full grow'>
            <SliderPrimitive.Range
              className={cn(
                percentages.display > 0.4 &&
                  percentages.display < 0.6 &&
                  'rounded-r-sm',
                percentages.display >= 0.6 && 'rounded-r-lg',
                'absolute flex h-full items-center',
                colourVariants.bg
              )}
            ></SliderPrimitive.Range>
          </SliderPrimitive.Track>
          <SliderPrimitive.Thumb
            className={cn(
              'group block h-10 w-3 rounded-r-lg bg-green-400 focus:outline-none',
              colourVariants.bg
            )}
            aria-label='Percentage of income to invest'
          >
            <div
              className={cn(
                'absolute inset-y-2 right-1 block w-1 rounded-full',
                colourVariants.thumb
              )}
            />
          </SliderPrimitive.Thumb>
        </SliderPrimitive.Root>
      </div>
    )
  }
)

Slider.displayName = SliderPrimitive.Root.displayName

export { Slider }
