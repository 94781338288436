import { IconButton } from '@/Components/Buttons'
import { cn } from '@/Lib'
import * as PopoverPrimitive from '@radix-ui/react-popover'
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'

const Popover = PopoverPrimitive.Root

const PopoverTrigger = PopoverPrimitive.Trigger

const PopoverContent = forwardRef<
  ElementRef<typeof PopoverPrimitive.Content>,
  ComponentPropsWithoutRef<typeof PopoverPrimitive.Content>
>(
  (
    {
      className,
      side = 'top',
      sideOffset = 5,
      collisionPadding = 16,
      children,
      ...props
    },
    ref
  ) => (
    <PopoverPrimitive.Portal>
      <PopoverPrimitive.Content
        ref={ref}
        side={side}
        sideOffset={sideOffset}
        collisionPadding={collisionPadding}
        className={cn(
          'relative flex w-full max-w-[calc(100vw-32px)] gap-2 rounded-xl bg-white p-4 shadow-lg sm:max-w-[440px]',
          className
        )}
        {...props}
      >
        {children}
        <PopoverPrimitive.Close asChild aria-label='Close'>
          <IconButton className='absolute right-4 top-4'>close</IconButton>
        </PopoverPrimitive.Close>
        <PopoverPrimitive.Arrow className='fill-white' />
      </PopoverPrimitive.Content>
    </PopoverPrimitive.Portal>
  )
)
PopoverContent.displayName = PopoverPrimitive.Content.displayName

export { Popover, PopoverContent, PopoverTrigger }
