import { cn } from '@/Lib'
import { InertiaLinkProps, Link } from '@inertiajs/react'
import type { AnchorHTMLAttributes, ReactNode, RefAttributes } from 'react'
import { forwardRef } from 'react'

interface RouterProps
  extends AnchorHTMLAttributes<HTMLAnchorElement>,
    RefAttributes<HTMLAnchorElement> {
  className?: string
  href: string
  children?: ReactNode
}

/**
 * Exposes the remix Link as a styled version.
 *
 * @param children The children of the Link.
 * @param href - Routes or Routes object with params
 * @param rest The props passed through to the anchor tag.
 */
export const Router = forwardRef<
  HTMLAnchorElement,
  InertiaLinkProps & RefAttributes<HTMLAnchorElement>
>(({ className, children, ...props }, ref) => {
  return (
    <Link
      ref={ref}
      className={cn(
        'rounded focus-visible:outline focus-visible:outline-2 focus-visible:outline-focus',
        className
      )}
      {...props}
    >
      {children}
    </Link>
  )
})

Router.displayName = 'Router'

/**
 * Exposes a headless anchor tag that will route to `to`,
 * and can be wrapped around a styled button for accessibility.
 *
 * @param children The children of the button.
 * @param href - Routes or Routes object with params
 * @param rest The props passed through to the anchor tag.
 */
export const AnchorRouter = forwardRef<HTMLAnchorElement, RouterProps>(
  ({ className, children, ...props }, ref) => {
    return (
      <a
        ref={ref}
        className={cn(
          'rounded font-medium text-primary focus-visible:outline focus-visible:outline-2 focus-visible:outline-blue-500',
          className
        )}
        rel='noreferrer'
        {...props}
      >
        {children}
      </a>
    )
  }
)

AnchorRouter.displayName = 'AnchorRouter'

interface ButtonRouterProps
  extends InertiaLinkProps,
    RefAttributes<HTMLAnchorElement> {
  className?: string
  children?: ReactNode
  shrink?: boolean
}

/**
 * Exposes a headless button that will route to `to`,
 * and can be wrapped around a styled button for accessibility.
 *
 * @param children The children of the button.
 * @param href - Routes or Routes object with params
 * @param rest The props passed through to the anchor tag.
 */
export const ButtonRouter = forwardRef<HTMLAnchorElement, ButtonRouterProps>(
  ({ className, children, href, shrink, ...props }, ref) => {
    return (
      <Link
        ref={ref}
        href={href}
        className={cn(
          'flex h-12 w-full items-center justify-center rounded-full border border-transparent bg-primary px-10 font-medium text-white hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-4 focus-visible:outline-blue-500',
          shrink && 'sm:max-w-fit',
          className
        )}
        {...props}
      >
        {children}
      </Link>
    )
  }
)

ButtonRouter.displayName = 'ButtonRouter'

export const OutlineButtonRouter = forwardRef<
  HTMLAnchorElement,
  ButtonRouterProps
>(({ className, children, href, shrink, ...props }, ref) => {
  return (
    <Link
      ref={ref}
      href={href}
      className={cn(
        'flex h-12 w-full items-center justify-center rounded-full border-2 border-blue-500 px-10 font-medium text-primary hover:bg-blue-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-4 focus-visible:outline-blue-500',
        shrink && 'sm:max-w-fit',
        className
      )}
      {...props}
    >
      {children}
    </Link>
  )
})

OutlineButtonRouter.displayName = 'OutlineButtonRouter'
