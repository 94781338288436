import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

/**
 * Use this function to merge and resolve className conflicts
 * Last conflicting class wins:
 * cn('p-5 p-2 p-4') → 'p-4'
 */
export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}
