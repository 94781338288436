import { Icon } from '@/Components/Icon'
import { Router } from '@/Components/Router'
import { cn } from '@/Lib'
import { usePage } from '@inertiajs/react'
import * as AccordionPrimitive from '@radix-ui/react-accordion'
import {
  ButtonHTMLAttributes,
  ComponentPropsWithoutRef,
  ElementRef,
  FC,
  HTMLAttributes,
  ReactNode,
  forwardRef,
  useMemo
} from 'react'

const VITE_APP_URL = import.meta.env.VITE_APP_URL || 'https://fynbos.money'

const Card = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn(
          'flex w-full flex-col rounded-card bg-container-strong p-2',
          className
        )}
        {...props}
      />
    )
  }
)
Card.displayName = 'Card'

const CardHeader = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn('flex w-full justify-between p-2', className)}
      {...props}
    />
  )
)
CardHeader.displayName = 'CardHeader'

const CardFooter = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn('flex w-full justify-between px-2 pb-2 pt-4', className)}
      {...props}
    />
  )
)
CardFooter.displayName = 'CardFooter'

const CardTitle = forwardRef<
  HTMLHeadingElement,
  HTMLAttributes<HTMLHeadingElement>
>(({ className, ...props }, ref) => {
  return (
    <h2
      ref={ref}
      className={cn('text-lg font-medium text-strong', className)}
      {...props}
    />
  )
})
CardTitle.displayName = 'CardTitle'

const CardIcon = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div
      ref={ref}
      className={cn(
        'flex items-center justify-between rounded-full bg-nav p-5 text-medium',
        className
      )}
      {...props}
    />
  )
)
CardIcon.displayName = 'CardIcon'

const CardContent = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => (
    <div ref={ref} className={cn('p-2', className)} {...props} />
  )
)
CardContent.displayName = 'CardContent'

type CardLinkProps = {
  children?: ReactNode
  className?: string
  href: string
}

const CardLink: FC<CardLinkProps> = ({ children, href, className }) => {
  const { url } = usePage()

  const to = useMemo(() => {
    const active = new URL(url, VITE_APP_URL)
    const current = new URL(href, VITE_APP_URL)
    return {
      href,
      active: current.pathname === active.pathname
    }
  }, [url, href])

  return (
    <Router
      href={to.href}
      data-active={to.active}
      preserveScroll
      className={cn(
        'group relative my-0.5 w-full truncate rounded-xl p-2 transition-colors first-of-type:mt-0 last-of-type:mb-0 focus-visible:outline focus-visible:outline-2 focus-visible:outline-focus',
        to.active ? 'bg-nav-active text-strong' : 'hover:bg-nav',
        className
      )}
    >
      {children}
    </Router>
  )
}

CardLink.displayName = 'CardLink'

interface CardButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  noHover?: boolean
}

const CardButton = forwardRef<HTMLButtonElement, CardButtonProps>(
  ({ className, noHover, ...props }, ref) => {
    return (
      <button
        ref={ref}
        className={cn(
          noHover && 'bg-nav',
          'my-1 flex rounded-xl p-3 first:mt-0 last-of-type:mb-0 hover:bg-nav focus-visible:outline-2 focus-visible:outline-focus active:bg-nav-hover',
          className
        )}
        {...props}
      />
    )
  }
)
CardButton.displayName = 'CardButton'

const CardAccordionItem = forwardRef<
  ElementRef<typeof AccordionPrimitive.Item>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
  <AccordionPrimitive.Item ref={ref} className={cn('', className)} {...props} />
))
CardAccordionItem.displayName = 'AccordionItem'

const CardAccordionTrigger = forwardRef<
  ElementRef<typeof AccordionPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Header asChild className='flex'>
    <AccordionPrimitive.Trigger
      ref={ref}
      className={cn(
        'flex w-full items-center justify-between p-2 [&[data-state=open]>span]:rotate-180',
        className
      )}
      {...props}
    >
      <CardTitle>{children}</CardTitle>
      <Icon className='transition-transform duration-200'>expand_more</Icon>
    </AccordionPrimitive.Trigger>
  </AccordionPrimitive.Header>
))
CardAccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName

const CardAccordionContent = forwardRef<
  ElementRef<typeof AccordionPrimitive.Content>,
  ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
  <AccordionPrimitive.Content
    ref={ref}
    className='flex flex-col gap-1 data-[state=open]:py-1'
    {...props}
  >
    <div className={cn(className)}>{children}</div>
  </AccordionPrimitive.Content>
))

CardAccordionContent.displayName = AccordionPrimitive.Content.displayName

export {
  Card,
  CardAccordionContent,
  CardAccordionItem,
  CardAccordionTrigger,
  CardButton,
  CardContent,
  CardFooter,
  CardHeader,
  CardIcon,
  CardLink,
  CardTitle
}
