import { cn } from '@/Lib'
import type { HTMLAttributes } from 'react'
import { forwardRef } from 'react'

const Grid = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ children }, ref) => {
    return (
      <div
        ref={ref}
        className='relative grid w-full grid-cols-4 content-start gap-4 sm:grid-cols-8 lg:grid-cols-12'
      >
        {children}
      </div>
    )
  }
)

Grid.displayName = 'WalletGrid'

const GridColumn = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(
  ({ className, ...props }, ref) => {
    return (
      <div
        ref={ref}
        className={cn('flex h-max max-h-max flex-col gap-y-4', className)}
        {...props}
      />
    )
  }
)

GridColumn.displayName = 'GridColumn'

export { Grid, GridColumn }
