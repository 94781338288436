import { cn } from '@/Lib'
import * as ProgressPrimitive from '@radix-ui/react-progress'
import { useAnimate } from 'framer-motion'
import {
  ComponentPropsWithoutRef,
  ElementRef,
  forwardRef,
  useEffect,
  useMemo
} from 'react'

const Progress = forwardRef<
  ElementRef<typeof ProgressPrimitive.Root>,
  ComponentPropsWithoutRef<typeof ProgressPrimitive.Root>
>(({ className, value, max, ...props }, ref) => {
  const [scope, animate] = useAnimate()

  const percentage = useMemo(() => {
    return ((value || 0) / (max ?? 100)) * 100
  }, [value, max])

  useEffect(() => {
    animate(
      '#progress-bar',
      { width: `${percentage || 0}%` },
      { duration: 0.3 }
    )
  }, [animate, percentage])

  return (
    <ProgressPrimitive.Root
      ref={scope || ref}
      className={cn('flex h-5 w-full rounded-full bg-slate-200 p-1', className)}
      {...props}
    />
  )
})
Progress.displayName = ProgressPrimitive.Root.displayName

const ProgressIndicator = forwardRef<
  ElementRef<typeof ProgressPrimitive.Indicator>,
  ComponentPropsWithoutRef<typeof ProgressPrimitive.Indicator>
>(({ className, ...props }, ref) => {
  return (
    <ProgressPrimitive.Indicator
      ref={ref}
      id='progress-bar'
      className={cn('h-full rounded-full bg-green-400', className)}
      style={{ width: `0%` }}
      {...props}
    />
  )
})
ProgressIndicator.displayName = ProgressPrimitive.Indicator.displayName

export { Progress, ProgressIndicator }
